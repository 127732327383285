import "./ToolIcon.scss";

import clsx from "clsx";
import type { ToolButtonSize } from "./ToolButton";
import { brainIcon, brainIconThin } from "./icons";
import DropdownMenu from "./dropdownMenu/DropdownMenu";

type GenAIButtonProps = {
  title?: string;
  name?: string;
  checked: boolean;
  onChange?(): void;
  isMobile?: boolean;
};

const DEFAULT_SIZE: ToolButtonSize = "medium";

const ICONS = {
  CHECKED: brainIcon,
  UNCHECKED: brainIconThin,
};

export const GenAIButton = (props: GenAIButtonProps) => {
  return (
    <label
      className={clsx(
        "ToolIcon ToolIcon__lock pl-10",
        `ToolIcon_size_${DEFAULT_SIZE}`,
        {
          "is-mobile": props.isMobile,
        },
      )}
      title={`${props.title} — Q`}
    >
      <input
        className="ToolIcon_type_checkbox"
        type="checkbox"
        name={props.name}
        onChange={props.onChange}
        checked={props.checked}
        aria-label={props.title}
        data-testid="toolbar-lock"
      />

      <DropdownMenu.Item.Badge>AI</DropdownMenu.Item.Badge>
    </label>
  );
};
// Add some basic styles for the file list
const styles = `
.ToolIcon.ToolIcon__lock.pl-10 {
    padding-left: 10px;
  }
`;

// Add the styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
