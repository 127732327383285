import { Dialog } from "../Dialog";
import { useApp, useExcalidrawSetAppState } from "../App";
import MermaidToExcalidraw from "./MermaidToExcalidraw";
import TTDDialogTabs from "./TTDDialogTabs";
import type { ChangeEventHandler } from "react";
import { useEffect, useRef, useState } from "react";
import { useUIAppState } from "../../context/ui-appState";
import { withInternalFallback } from "../hoc/withInternalFallback";
import { TTDDialogTabTriggers } from "./TTDDialogTabTriggers";
import { TTDDialogTabTrigger } from "./TTDDialogTabTrigger";
import { TTDDialogTab } from "./TTDDialogTab";
import { t } from "../../i18n";
import { TTDDialogInput } from "./TTDDialogInput";
import { TTDDialogOutput } from "./TTDDialogOutput";
import { TTDDialogPanel } from "./TTDDialogPanel";
import { TTDDialogPanels } from "./TTDDialogPanels";
import type { MermaidToExcalidrawLibProps } from "./common";
import {
  convertMermaidToExcalidraw,
  insertToEditor,
  saveMermaidDataToStorage,
} from "./common";
import type { NonDeletedExcalidrawElement } from "../../element/types";
import type { BinaryFiles } from "../../types";
import { ArrowRightIcon } from "../icons";
import { Upload } from "lucide-react";
import "./TTDDialog.scss";
import { atom, useAtom } from "jotai";
import { trackEvent } from "../../analytics";
import { InlineIcon } from "../InlineIcon";
import { TTDDialogSubmitShortcut } from "./TTDDialogSubmitShortcut";
import { isFiniteNumber } from "../../../math";
import { Button } from "../Button";
import clsx from "clsx";
import Spinner from "../Spinner";

const MIN_PROMPT_LENGTH = 3;
const MAX_PROMPT_LENGTH = 1000;
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = [
  "text/plain",
  "application/pdf",
  "text/markdown",
  "text/csv",
  "application/json",
  "image/jpg",
  "image/jpeg",
  "image/png",
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const rateLimitsAtom = atom<{
  rateLimit: number;
  rateLimitRemaining: number;
} | null>(null);

const ttdGenerationAtom = atom<{
  generatedResponse: string | null;
  prompt: string | null;
} | null>(null);

type OnTestSubmitRetValue = {
  rateLimit?: number | null;
  rateLimitRemaining?: number | null;
} & (
  | { generatedResponse: string | undefined; error?: null | undefined }
  | {
      error: Error;
      generatedResponse?: null | undefined;
    }
);

export const TTDDialog = (
  props:
    | {
        onTextSubmit(
          value: string,
          files?: File[],
        ): Promise<OnTestSubmitRetValue>;
      }
    | { __fallback: true },
) => {
  const appState = useUIAppState();

  if (appState.openDialog?.name !== "ttd") {
    return null;
  }

  return <TTDDialogBase {...props} tab={appState.openDialog.tab} />;
};

export const TTDDialogBase = withInternalFallback(
  "TTDDialogBase",
  ({
    tab,
    ...rest
  }: {
    tab: "text-to-diagram" | "mermaid";
  } & (
    | {
        onTextSubmit(
          value: string,
          files?: File[],
        ): Promise<OnTestSubmitRetValue>;
      }
    | { __fallback: true }
  )) => {
    const app = useApp();
    const setAppState = useExcalidrawSetAppState();

    const someRandomDivRef = useRef<HTMLDivElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [ttdGeneration, setTtdGeneration] = useAtom(ttdGenerationAtom);
    const [text, setText] = useState(ttdGeneration?.prompt ?? "");
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [onTextSubmitInProgess, setOnTextSubmitInProgess] = useState(false);
    const [rateLimits, setRateLimits] = useAtom(rateLimitsAtom);
    const [error, setError] = useState<Error | null>(null);

    const prompt = text.trim();

    const handleTextChange: ChangeEventHandler<HTMLTextAreaElement> = (
      event,
    ) => {
      setText(event.target.value);
      setTtdGeneration((s) => ({
        generatedResponse: s?.generatedResponse ?? null,
        prompt: event.target.value,
      }));
    };

    const handleFileChange = async (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const files = event.target.files;
      if (!files) {
        return;
      }

      const validFiles = Array.from(files).filter((file) => {
        if (!ALLOWED_FILE_TYPES.includes(file.type)) {
          alert(
            `Unsupported file type: ${
              file.name
            }. Allowed types: ${ALLOWED_FILE_TYPES.join(", ")}`,
          );
          return false;
        }

        if (file.size > MAX_FILE_SIZE) {
          alert(`File ${file.name} is too large. Maximum file size is 5MB.`);
          return false;
        }

        return true;
      });

      setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
      console.log("Files selected:", validFiles); // Debug log
    };

    const removeFile = (fileToRemove: File) => {
      setSelectedFiles((prevFiles) =>
        prevFiles.filter((file) => file !== fileToRemove),
      );
    };

    // Modifica la definizione del tipo per includere correttamente i files
    type OnTestSubmitRetValue = {
      rateLimit?: number | null;
      rateLimitRemaining?: number | null;
    } & (
      | { generatedResponse: string | undefined; error?: null | undefined }
      | { error: Error; generatedResponse?: null | undefined }
    );

    // Aggiungi una funzione per gestire la preparazione dei files
    const prepareFilesForUpload = async (files: File[]) => {
      const formData = new FormData();

      for (const file of files) {
        formData.append("files", file);
      }

      return formData;
    };

    const onGenerate = async () => {
      // Se non ci sono files, il prompt deve rispettare i limiti di lunghezza
      // Se ci sono files, il prompt è opzionale
      if (
        (!selectedFiles.length &&
          (prompt.length < MIN_PROMPT_LENGTH ||
            prompt.length > MAX_PROMPT_LENGTH)) ||
        onTextSubmitInProgess ||
        rateLimits?.rateLimitRemaining === 0 ||
        "__fallback" in rest
      ) {
        // Mostra errori solo se non ci sono files
        if (!selectedFiles.length) {
          if (prompt.length < MIN_PROMPT_LENGTH) {
            setError(
              new Error(
                `Il testo inserito è troppo breve (min ${MIN_PROMPT_LENGTH} caratteri). Aggiungi testo o carica dei files.`,
              ),
            );
          }
          if (prompt.length > MAX_PROMPT_LENGTH) {
            setError(
              new Error(
                `Il prompt è troppo lungo (max ${MAX_PROMPT_LENGTH} caratteri)`,
              ),
            );
          }
        }
        return;
      }

      // Se non c'è testo e non ci sono files, non permettere l'invio
      if (!prompt && !selectedFiles.length) {
        setError(new Error("Inserisci del testo o carica dei files"));
        return;
      }

      try {
        setOnTextSubmitInProgess(true);
        trackEvent("ai", "generate", "ttd");

        const submitMethod = "onTextSubmit" in rest ? rest.onTextSubmit : null;

        // Prima chiamata rimossa
        const response = submitMethod
          ? await submitMethod(prompt, selectedFiles)
          : {
              generatedResponse: null,
              error: new Error("No submit method"),
              rateLimit: null,
              rateLimitRemaining: null,
            };

        console.log("API Response:", response); // Aggiungi questo log

        // Destruttura tutti i valori in una volta sola
        const { generatedResponse, error, rateLimit, rateLimitRemaining } =
          response;

        if (typeof generatedResponse === "string") {
          setTtdGeneration((s) => ({
            generatedResponse,
            prompt: s?.prompt ?? null,
          }));
        }

        // Modifica questa condizione
        if (rateLimit && rateLimitRemaining) {
          console.log("Setting rate limits:", {
            rateLimit,
            rateLimitRemaining,
          }); // Aggiungi questo log
          setRateLimits({ rateLimit, rateLimitRemaining });
        }

        if (error) {
          setError(error);
          return;
        }

        if (!generatedResponse) {
          setError(new Error("Generation failed"));
          return;
        }

        try {
          await convertMermaidToExcalidraw({
            canvasRef: someRandomDivRef,
            data,
            mermaidToExcalidrawLib,
            setError,
            mermaidDefinition: generatedResponse,
          });
          trackEvent("ai", "mermaid parse success", "ttd");
        } catch (error: any) {
          console.error("TTD mermaid render error:", error);
          trackEvent("ai", "mermaid parse failed", "ttd");
          setError(
            new Error(
              generatedResponse,
            ),
          );
        }
      } catch (error: any) {
        const message = error.message || "Request failed";
        console.error("Generation error:", error);
        setError(new Error(message));
      } finally {
        setOnTextSubmitInProgess(false);
      }
    };

    const refOnGenerate = useRef(onGenerate);
    refOnGenerate.current = onGenerate;

    const [mermaidToExcalidrawLib, setMermaidToExcalidrawLib] =
      useState<MermaidToExcalidrawLibProps>({
        loaded: false,
        api: import("@excalidraw/mermaid-to-excalidraw"),
      });

    useEffect(() => {
      const fn = async () => {
        await mermaidToExcalidrawLib.api;
        setMermaidToExcalidrawLib((prev) => ({ ...prev, loaded: true }));
      };
      fn();
    }, [mermaidToExcalidrawLib.api]);

    const data = useRef<{
      elements: readonly NonDeletedExcalidrawElement[];
      files: BinaryFiles | null;
    }>({ elements: [], files: null });

    const renderFileList = () => {
      if (selectedFiles.length === 0) {
        return null;
      }

      return (
        <div className="ttd-dialog-file-list">
          {selectedFiles.map((file, index) => (
            <div key={index} className="ttd-dialog-file-item">
              <span>{file.name}</span>
              <button
                type="button"
                onClick={() => removeFile(file)}
                style={{
                  background: "none",
                  border: "none",
                  color: "var(--color-danger)",
                  cursor: "pointer",
                }}
              >
                ✕
              </button>
            </div>
          ))}
        </div>
      );
    };
    useEffect(() => {
      console.log("rateLimits changed:", rateLimits);
    }, [rateLimits]);
    return (
      <Dialog
        className="ttd-dialog"
        onCloseRequest={() => {
          app.setOpenDialog(null);
        }}
        size={1200}
        title={false}
        {...rest}
        autofocus={false}
      >
        <TTDDialogTabs dialog="ttd" tab={tab}>
          {"__fallback" in rest && rest.__fallback ? (
            <p className="dialog-mermaid-title">{t("mermaid.title")}</p>
          ) : (
            <TTDDialogTabTriggers>
              <TTDDialogTabTrigger tab="text-to-diagram">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {t("labels.textToDiagram")}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "1px 6px",
                      marginLeft: "10px",
                      fontSize: 10,
                      borderRadius: "12px",
                      background: "var(--color-promo)",
                      color: "var(--color-surface-lowest)",
                    }}
                  >
                    AI Beta
                  </div>
                </div>
              </TTDDialogTabTrigger>
              <TTDDialogTabTrigger tab="mermaid">Sorgente</TTDDialogTabTrigger>
            </TTDDialogTabTriggers>
          )}

          <TTDDialogTab className="ttd-dialog-content" tab="mermaid">
            <MermaidToExcalidraw
              mermaidToExcalidrawLib={mermaidToExcalidrawLib}
            />
          </TTDDialogTab>

          {!("__fallback" in rest) && (
            <TTDDialogTab className="ttd-dialog-content" tab="text-to-diagram">
              <div className="ttd-dialog-desc">
                Genera la tua mappa mentale utilizzando l'intelligenza
                artificiale di Smart Home Studying
              </div>
              <TTDDialogPanels>
                <TTDDialogPanel
                  label={t("labels.prompt")}
                  panelAction={{
                    action: onGenerate,
                    label: rateLimits
                      ? `Genera (${
                          rateLimits.rateLimit - rateLimits.rateLimitRemaining
                        }/${rateLimits.rateLimit})`
                      : "Generate",
                    icon: ArrowRightIcon,
                  }}
                  onTextSubmitInProgess={onTextSubmitInProgess}
                  panelActionDisabled={
                    (!selectedFiles.length &&
                      (prompt.length > MAX_PROMPT_LENGTH ||
                        prompt.length < MIN_PROMPT_LENGTH)) ||
                    (!prompt && !selectedFiles.length) ||
                    rateLimits?.rateLimitRemaining === 0
                  }
                  renderTopRight={() => {
                    if (!rateLimits) {
                      return null;
                    }

                    return (
                      <div
                        className="ttd-dialog-rate-limit"
                        style={{
                          fontSize: 12,
                          marginLeft: "auto",
                          color:
                            rateLimits.rateLimitRemaining === 0
                              ? "var(--color-danger)"
                              : undefined,
                        }}
                      >
                        {rateLimits.rateLimitRemaining} richieste rimaste per
                        questo mese
                      </div>
                    );
                  }}
                  renderSubmitShortcut={() => <TTDDialogSubmitShortcut />}
                  renderBottomRight={() => {
                    if (typeof ttdGeneration?.generatedResponse === "string") {
                      return (
                        <div
                          className="excalidraw-link"
                          style={{ marginLeft: "auto", fontSize: 14 }}
                          onClick={() => {
                            if (
                              typeof ttdGeneration?.generatedResponse ===
                              "string"
                            ) {
                              saveMermaidDataToStorage(
                                ttdGeneration.generatedResponse,
                              );
                              setAppState({
                                openDialog: { name: "ttd", tab: "mermaid" },
                              });
                            }
                          }}
                        >
                          Visualizza sorgente
                          <InlineIcon icon={ArrowRightIcon} />
                        </div>
                      );
                    }
                    const ratio = prompt.length / MAX_PROMPT_LENGTH;
                    if (ratio > 0.8) {
                      return (
                        <div
                          style={{
                            marginLeft: "auto",
                            fontSize: 12,
                            fontFamily: "monospace",
                            color:
                              ratio > 1 ? "var(--color-danger)" : undefined,
                          }}
                        >
                          Length: {prompt.length}/{MAX_PROMPT_LENGTH}
                        </div>
                      );
                    }
                    return null;
                  }}
                >
                  <div>
                    <TTDDialogInput
                      onChange={handleTextChange}
                      input={text}
                      placeholder={"Descrivi cosa vorresti generare..."}
                      onKeyboardSubmit={() => {
                        refOnGenerate.current();
                      }}
                    />
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      multiple
                      style={{ display: "none" }}
                      accept={ALLOWED_FILE_TYPES.join(",")}
                    />
                    <Button
                      className="ttd-dialog-panel-button mt-2"
                      onSelect={() => fileInputRef.current?.click()}
                    >
                      <div
                        className={clsx({ invisible: onTextSubmitInProgess })}
                      >
                        <Upload /> Carica Files
                      </div>
                    </Button>
                    {renderFileList()}
                  </div>
                </TTDDialogPanel>
                <TTDDialogPanel
                  label="Anteprima"
                  panelAction={{
                    action: () => {
                      console.info("Panel action clicked");
                      insertToEditor({ app, data });
                    },
                    label: "Inserisci",
                    icon: ArrowRightIcon,
                  }}
                >
                  <TTDDialogOutput
                    canvasRef={someRandomDivRef}
                    error={error}
                    loaded={mermaidToExcalidrawLib.loaded}
                  />
                </TTDDialogPanel>
              </TTDDialogPanels>
            </TTDDialogTab>
          )}
        </TTDDialogTabs>
      </Dialog>
    );
  },
);

// Add some basic styles for the file list
const styles = `
.ttd-dialog-file-list {
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.ttd-dialog-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  background: var(--color-surface-lowest);
  border: 1px solid var(--color-border);
  border-radius: 4px;
  font-size: 12px;
}
.excalidraw .ttd-dialog-panel textarea {
  height: 150px;
  resize: none;
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--dialog-border-color);
  white-space: pre-wrap;
  padding: 0.85rem;
  box-sizing: border-box;
  width: 100%;
  font-family: monospace;
}
.ttd-dialog-panel-button.mt-2 {
   margin-top: -50px;
  position: absolute;
  margin-left: 10px;
`;

// Add the styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default TTDDialog;
