import type { ExcalidrawImperativeAPI } from "../../packages/excalidraw/types";
import {
  DiagramToCodePlugin,
  exportToBlob,
  getTextFromElements,
  MIME_TYPES,
  TTDDialog,
} from "../../packages/excalidraw";
import { getDataURL } from "../../packages/excalidraw/data/blob";
import { safelyParseJSON } from "../../packages/excalidraw/utils";
import { useEffect, useState } from "react";
export const AIComponents = ({
  excalidrawAPI,
}: {
  excalidrawAPI: ExcalidrawImperativeAPI;
}) => {
  // Funzione per ottenere il token dall'URL
  const getTokenFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("token");
  };
  // State per il token
  const [token, setToken] = useState<string | null>(null);

  // Leggi il token all'avvio
  useEffect(() => {
    const urlToken = getTokenFromUrl();
    if (urlToken) {
      setToken(urlToken);
    }
  }, []);
  return (
    <>
      <TTDDialog
        onTextSubmit={async (input, files) => {
          try {
            const formData = new FormData();

            // Aggiungi il prompt solo se presente
            if (input.trim()) {
              formData.append("prompt", input);
            }
            if (token) {
              formData.append("token", token);
            }
            // Aggiungi i files se presenti
            if (files && files.length > 0) {
              files.forEach((file) => {
                formData.append("files", file);
              });
            }

            // Verifica che ci sia almeno un input
            if (!input.trim() && (!files || files.length === 0)) {
              throw new Error("Please provide either text or files");
            }

            const response = await fetch(
              `${
                import.meta.env.VITE_APP_AI_BACKEND
              }/v1/ai/text-to-diagram/generate`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                },
                body: formData,
              },
            );
            const headers = Array.from(response.headers.entries());
            console.log("Response headers:", headers);
            const rateLimit = response.headers.has("X-RateLimit-Limit")
              ? parseInt(response.headers.get("X-RateLimit-Limit") || "0", 10)
              : undefined;
            const rateLimitRemaining = response.headers.has(
              "X-RateLimit-Remaining",
            )
              ? parseInt(
                  response.headers.get("X-RateLimit-Remaining") || "0",
                  10,
                )
              : undefined;

            const json = await response.json();

            if (!response.ok) {
              if (response.status === 429) {
                return {
                  rateLimit,
                  rateLimitRemaining,
                  error: new Error(
                    "Hai effettuato troppe richieste per questo mese, riprova dal prossimo!!",
                  ),
                };
              }

              throw new Error(json.message || "Generazione fallita...");
            }

            const generatedResponse = json.generatedResponse;
            if (!generatedResponse) {
              throw new Error("Generazione fallita...");
            }

            return { generatedResponse, rateLimit, rateLimitRemaining };
          } catch (err: any) {
            console.error("Errore nel caricamento del file:", err);
            throw new Error("Richiesta fallita");
          }
        }}
      />
    </>
  );
};
